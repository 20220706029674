import React from 'react';

import CreditCardIcon from './CreditCardIcon';


const Visa = (props) => (
  <CreditCardIcon {...props}>
    <rect fill="#1A1F71" height="24" rx="3" width="37" /><path d="M19.067 10.197c-.019 1.393 1.323 2.17 2.334 2.633 1.038.474 1.387.778 1.383 1.202-.008.649-.829.935-1.597.946-1.34.02-2.118-.339-2.738-.61l-.482 2.119c.621.268 1.772.503 2.965.513 2.8 0 4.632-1.297 4.642-3.309.011-2.552-3.762-2.693-3.737-3.834.01-.346.361-.715 1.132-.81.382-.047 1.435-.083 2.629.433l.469-2.05A7.562 7.562 0 0 0 23.572 7c-2.636 0-4.49 1.315-4.505 3.197zm11.503-3.02c-.51 0-.942.28-1.134.71l-4 8.962h2.798l.557-1.444h3.42l.323 1.444H35l-2.152-9.672H30.57zm.392 2.613l.808 3.632h-2.212l1.404-3.632zM15.674 7.177l-2.206 9.672h2.667l2.204-9.672h-2.665zm-3.945 0L8.953 13.76 7.831 8.162c-.132-.625-.652-.985-1.23-.985H2.063L2 7.457c.931.19 1.99.496 2.63.823.393.2.505.375.634.85l2.127 7.72h2.818l4.32-9.673h-2.8z" fill="#fff" />
  </CreditCardIcon>
);

export default Visa;
