import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';

import { VALUE_PARKING } from '../constants/api';
import {
  COLOR_BLACK, COLOR_GRAY, COLOR_RED, COLOR_WHITE,
} from '../constants/colors';
import { buttonStyles, fontStyles } from '../constants/styles';
import { parseToNumber, handleNumberInput } from '../helpers/numbers';
import { ADD_SERVICE } from '../state/actionTypes';

import FormattedPrice from './FormattedPrice';
import PlusSymbol from './PlusSymbol';
import MinusSymbol from './MinusSymbol';
import StyledFieldset from './StyledFieldset';
import { SelectWrapper } from './Form';


export const Container = styled.div`
  ${fontStyles.bodySmall}
  padding: 2em 0;
  border-bottom: 1px solid ${COLOR_BLACK};
`;
export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  ${fontStyles.body}
`;
export const Description = styled.div`
  ${fontStyles.bodySmall}
`;
export const CounterWidget = styled.div`
  margin-top: 2em;
  ${fontStyles.bodySmall}

  label > p {
    ${fontStyles.uppercase}
  }

  input:out-of-range {
    color: ${COLOR_RED};
  }
`;
export const CounterFieldset = styled(StyledFieldset)`
  margin-top: .5rem;
  padding-left: 0;

  > span:first-of-type {
    margin-left: 1rem;
  }
`;
export const StyledSelect = styled.select`
  display: block;
  width: 100%;
  padding: .25em .5em;
  border: 1px solid ${COLOR_BLACK};
  border-radius: 0;
  background-color: transparent;
  ${fontStyles.bodySmall}
`;
export const Summary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
`;
export const Total = styled.div`
  ${fontStyles.body}
`;
export const AddButton = styled.button`
  display: inline-block;
  ${buttonStyles}
  border-color: ${(props) => (props.disabled ? COLOR_GRAY : COLOR_BLACK)};
  color: ${(props) => (props.disabled ? COLOR_GRAY : COLOR_BLACK)};
  background-color: ${COLOR_WHITE};
`;


const getTimeOptions = (formatTime, arrivalDate, { start, end, increment }) => {
  let current = moment(`${arrivalDate}${start}`);
  const finish = moment(`${arrivalDate}${end}`);
  const duration = moment.duration(increment);

  const options = [];
  while (current < finish) {
    options.push({
      value: current.format('YYYY-MM-DDTHH:mm:ss'),
      label: formatTime(current),
    });
    current = current.add(duration);
  }
  return options;
};

class AddOn extends Component {
  constructor(props) {
    super(props);

    const { intl: { formatTime }, initialState: { numAdultsSelected, numChildrenSelected, numSelected, total }, priceClasses, start, timeSpan } = this.props;

    this.state = {
      total: total || 0,
    };

    if (!priceClasses) {
      this.state.numSelected = numSelected || 0;
    } else {
      if (priceClasses.length > 2) {
        console.log('Received more than 2 price classes');
      }
      this.state.numAdultsSelected = numAdultsSelected || 0;
      this.state.numChildrenSelected = numChildrenSelected || 0;
    }

    this.select = React.createRef();

    if (timeSpan) {
      this.timeOptions = getTimeOptions(formatTime, start, timeSpan);
    }

    this.createChangeHandler = this.createChangeHandler.bind(this);
    this.incrementStateKey = this.incrementStateKey.bind(this);
    this.decrementStateKey = this.decrementStateKey.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }

  incrementStateKey(target, key, amount) {
    const step = parseFloat(amount);

    this.setState((prevState) => {
      const prevValue = parseToNumber(prevState[key]);
      const newValue = prevValue + 1;
      return {
        total: prevState.total + step,
        [key]: newValue,
      };
    });
  }

  decrementStateKey(target, key, amount) {
    const step = parseFloat(amount);

    this.setState((prevState) => {
      const prevValue = parseToNumber(prevState[key]);
      const newValue = prevValue - 1;
      return {
        total: prevState.total - step,
        [key]: newValue,
      };
    });
  }

  createChangeHandler(key, amount) {
    return ({ target }) => {
      const step = parseFloat(amount);
      const newValue = handleNumberInput(target);

      if (typeof (newValue) !== 'undefined') {
        this.setState((prevState) => {
          const prevValue = parseToNumber(prevState[key]);
          const diff = newValue - prevValue; // Empty string is coereced to 0
          return {
            total: prevState.total + (diff * step),
            [key]: newValue,
          };
        });
      }
    };
  }

  handleAdd() {
    const { addService, code, name, roomId, start, end, timeSpan } = this.props;
    const { numAdultsSelected, numChildrenSelected, numSelected, total } = this.state;
    let payload;
    if (numSelected != null) {
      payload = {
        code,
        name,
        quantity: parseInt(numSelected, 10),
        guests: {
          adults: parseInt(numSelected, 10),
          children: 0,
        },
        start,
        end,
        total,
      };
    } else {
      const adults = parseInt(numAdultsSelected, 10);
      const children = parseInt(numChildrenSelected, 10);
      payload = {
        code,
        quantity: adults + children,
        guests: {
          adults,
          children,
        },
        name,
        start,
        end,
        total,
      };
    }
    if (timeSpan) {
      const selectedTime = this.select?.current?.value || this.timeOptions[0].value;
      const timeSpanStart = moment(selectedTime);
      const duration = moment.duration(timeSpan.increment);
      const timeSpanEnd = timeSpanStart.clone().add(duration);
      payload.start = timeSpanStart.format().slice(0, 19);
      payload.end = timeSpanEnd.format().slice(0, 19);
    }
    addService(payload, roomId);
  }

  render() {
    const {
      alreadyAdded, basePrice, code, description, numNights, numAdults,
      numChildren = 0, priceClasses, timeSpan, title,
    } = this.props;
    const { total } = this.state;
    const disabled = alreadyAdded || total === 0;
    const numGuests = numAdults + numChildren;
    const isParking = code === VALUE_PARKING;
    return (
      <Container>
        <Heading>
          {title}
        </Heading>
        <Description>{description}</Description>
        <Choose>
          <When condition={priceClasses}>
            <For each="p" index="idx" of={priceClasses}>
              <With inputId={`num${idx > 0 ? 'Children' : 'Adults'}Selected`} isChildren={idx > 0}>
                <CounterWidget key={p.minAge}>
                  <label htmlFor={inputId}>
                    <p>
                      <FormattedMessage
                        description="Label for the number of adults or children"
                        id={isChildren ? 'addons.numberOfChildren' : 'addons.numberOfAdults'}
                      />
                    </p>
                    <CounterFieldset>
                      <button
                        disabled={alreadyAdded || this.state[inputId] < 1}
                        onClick={(e) => this.decrementStateKey(e.target, inputId, (p.price * numNights))}
                        type="button"
                      >
                        <MinusSymbol width="2px" />
                      </button>
                      <button
                        disabled={alreadyAdded || (isChildren ? this.state[inputId] >= numChildren : this.state[inputId] >= numAdults)}
                        onClick={(e) => this.incrementStateKey(e.target, inputId, (p.price * numNights))}
                        type="button"
                      >
                        <PlusSymbol width="2px" />
                      </button>
                      <input
                        id={inputId}
                        max={isChildren ? numChildren : numAdults}
                        min="0"
                        onChange={this.createChangeHandler(inputId, (p.price * numNights))}
                        type="number"
                        value={this.state[inputId]}
                      />
                      <span>
                        <FormattedPrice fractionOptional value={p.price} />&nbsp;
                        <FormattedMessage
                          description="Short label for the cost per adult or child per day"
                          id={isChildren ? 'addons.costPerChildShort' : 'addons.costPerAdultShort'}
                        />
                      </span>
                    </CounterFieldset>
                  </label>&nbsp;
                </CounterWidget>
              </With>
            </For>
          </When>
          <When condition={basePrice}>
            <CounterWidget>
              <With inputId="numSelected">
                <label htmlFor={inputId}>
                  <p>
                    <Choose>
                      <When condition={isParking}>
                        <FormattedMessage
                          description="Label for the number of vehicles"
                          id="addons.numberOfVehicles"
                        />
                      </When>
                      <Otherwise>
                        <FormattedMessage
                          description="Label for the number of people"
                          id="addons.numberOfPeople"
                        />
                      </Otherwise>
                    </Choose>
                  </p>
                  <CounterFieldset>
                    <button
                      disabled={alreadyAdded || this.state[inputId] < 1}
                      onClick={(e) => this.decrementStateKey(e.target, inputId, (basePrice * numNights))}
                      type="button"
                    >
                      <MinusSymbol width="2px" />
                    </button>
                    <button
                      disabled={alreadyAdded || (!isParking ? this.state[inputId] >= numGuests : false)}
                      onClick={(e) => this.incrementStateKey(e.target, inputId, (basePrice * numNights))}
                      type="button"
                    >
                      <PlusSymbol width="2px" />
                    </button>
                    <input
                      disabled={alreadyAdded}
                      id={inputId}
                      max={isParking ? null : numGuests}
                      min="0"
                      onChange={this.createChangeHandler(inputId, basePrice * numNights)}
                      type="number"
                      value={this.state[inputId]}
                    />
                    <span>
                      <FormattedPrice fractionOptional value={basePrice} />&nbsp;
                      <Choose>
                        <When condition={code === VALUE_PARKING}>
                          <FormattedMessage
                            description="Short label for the cost per vehicle per night"
                            id="addons.costPerVehicleShort"
                          />
                        </When>
                        <Otherwise>
                          <FormattedMessage
                            description="Short label for the cost per person per day"
                            id="addons.costPerPersonShort"
                          />
                        </Otherwise>
                      </Choose>
                    </span>
                  </CounterFieldset>
                </label>
              </With>
            </CounterWidget>
            <If condition={timeSpan}>
              <CounterWidget>
                <label htmlFor="time">{/* eslint-disable-line jsx-a11y/label-has-for */}
                  <p>
                    <FormattedMessage
                      defaultMessage="Time"
                      description="Label for the timespan"
                      id="addons.time"
                    />
                  </p>
                  <SelectWrapper style={{ width: '7em' }}>
                    <StyledSelect id="time" innerRef={this.select}>
                      <For each="opt" of={this.timeOptions}>
                        <option key={opt.value} value={opt.value}>{opt.label}</option>
                      </For>
                    </StyledSelect>
                  </SelectWrapper>
                </label>
              </CounterWidget>
            </If>
          </When>
        </Choose>
        <Summary>
          <Total>
            <span>
              <FormattedMessage
                description="Label for the total costs"
                id="booking.total"
              />:&nbsp;
              <FormattedPrice value={total} />
            </span>
          </Total>
          <AddButton disabled={disabled} onClick={this.handleAdd} type="button"><FormattedMessage id="addons.addButton" /></AddButton>
        </Summary>
      </Container>
    );
  }
}

AddOn.propTypes = {
  addService: PropTypes.func.isRequired,
  alreadyAdded: PropTypes.bool,
  basePrice: PropTypes.number,
  code: PropTypes.string.isRequired,
  description: PropTypes.node,
  end: PropTypes.string.isRequired,
  initialState: PropTypes.object,
  intl: intlShape,
  name: PropTypes.string.isRequired,
  numNights: PropTypes.number.isRequired,
  numAdults: PropTypes.number.isRequired,
  numChildren: PropTypes.number,
  priceClasses: PropTypes.arrayOf(PropTypes.shape({
    minAge: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
  })),
  roomId: PropTypes.number,
  start: PropTypes.string.isRequired,
  timeSpan: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    increment: PropTypes.string.isRequired,
  }),
  title: PropTypes.node.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  addService: (payload, roomId) => dispatch({ type: ADD_SERVICE, payload, roomId }),
});

export default injectIntl(connect(null, mapDispatchToProps)(AddOn));
