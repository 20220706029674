import styled from 'styled-components';

import { COLOR_BLACK, COLOR_GRAY } from '../constants/colors';


export default styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
  color: ${(props) => props.color || COLOR_BLACK};

  > * {
    order: 2;
  }

  button:first-child {
    order: 0;
  }

  button:disabled {
    color: ${(props) => props.color || COLOR_GRAY};

    > svg line {
      stroke: ${(props) => props.color || COLOR_GRAY}
    }
  }

  button,
  input {
    font-size: 1rem;
    background-color: rgba(255, 255, 255, 0);
    color: inherit;
  }

  button {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  input {
    order: 1;
    margin: 0;
    padding: 0 0.5rem;
    width: 50px;
    border: none;
    text-align: center;
    appearance: textfield;
  }

  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
