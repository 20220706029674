import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fontStyles } from '../constants/styles';


const Wrapper = styled.div`
  margin-top: 1em;
  ${fontStyles.checkout}
`;

const PolicyName = styled.p`
  ${fontStyles.checkoutUppercase}
`;

const Policy = ({ name, children }) => (
  <Wrapper>
    <PolicyName>{typeof name === 'string' ? name.toLowerCase() : name}</PolicyName>
    {children}
  </Wrapper>
);

Policy.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

export default Policy;
