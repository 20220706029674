import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';

import {
  BOOKING_ADDONS_PAGE, BOOKING_ADD_ROOM_PAGE, BOOKING_DETAILS_PAGE,
  BOOKING_CONFIRMATION_PAGE, BOOKING_RESERVATION_PAGE,
} from '../constants/locations';
import { getLocaleFromURI, getURIWithoutLocale } from '../helpers/location';
import { getRetrievedReservation, getStay } from '../state/reducers';

import AddOnsPage from './AddOnsPage';
import AddRoomPage from './AddRoomPage';
import ConfirmationPage from './ConfirmationPage';
import DetailsPage from './DetailsPage';
import ReservationPage from './ReservationPage';

/* This is a special route component that is connected to the store so that we
 * can check whether we have a chosen stay before attempting to render the page.
 * If we do, we render the correct page component.
 * If not, we redirect the user to the rooms page.
 * TODO: It's hard to identify the rooms page slugs at runtime, and in any case
 * we're hardcoding them in the Netlify redirects file, so we're also doing the
 * same here. If the slugs ever change though, this will require updating (as
 * will the _redirects file.)
 */
const BookingRoute = ({ reservation, stay, ...props }) => {
  const fullPath = props.path.replace('*', props['*']);

  if (!reservation && !stay) {
    const locale = getLocaleFromURI(fullPath);
    const slug = locale === 'en' ? 'rooms' : 'zimmer';
    return <Redirect noThrow to={`/${locale}/${slug}`} />;
  }

  const path = getURIWithoutLocale(fullPath);

  if (stay) {
    if (path === BOOKING_ADDONS_PAGE) {
      return (
        <AddOnsPage {...props} />
      );
    }
    if (path === BOOKING_ADD_ROOM_PAGE) {
      return (
        <AddRoomPage {...props} />
      );
    }
    if (path === BOOKING_DETAILS_PAGE) {
      return (
        <DetailsPage {...props} />
      );
    }
    if (path === BOOKING_CONFIRMATION_PAGE) {
      return (
        <ConfirmationPage {...props} />
      );
    }
  }

  if (reservation) {
    if (path === BOOKING_RESERVATION_PAGE) {
      return (
        <ReservationPage {...props} />
      );
    }
  }

  return null;
};

BookingRoute.propTypes = {
  '*': PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  reservation: PropTypes.object,
  stay: PropTypes.object,
};

const mapStateToProps = (state) => ({
  reservation: getRetrievedReservation(state),
  stay: getStay(state),
});

export default connect(mapStateToProps)(BookingRoute);
