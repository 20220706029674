import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import {
  injectIntl, intlShape, FormattedDate, FormattedMessage,
} from 'react-intl';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

import { getSingletonForLang } from '../helpers/i18n';
import { handleNumberInput, parseToNumber } from '../helpers/numbers';
import { BOOKING_ADD_ROOM_REQUESTED } from '../state/actionTypes';
import {
  getAvailableServices, getMaxOccupancy, getStay, getWindowSize,
} from '../state/reducers';
import { windowSizeTypes } from '../types';

import {
  AddButton, Container, CounterFieldset, CounterWidget, Description, Heading,
} from './AddOn';
import BookingLayout from './BookingLayout';
import ErrorMessage from './ErrorMessage';
import MinusSymbol from './MinusSymbol';
import PlusSymbol from './PlusSymbol';


const getBreadcrumbs = (addOnsAvailable) => (
  <ol>
    <If condition={addOnsAvailable}>
      <li>
        <FormattedMessage defaultMessage="Add Ons" id="addons.breadcrumbTitle" />
      </li>
    </If>
    <li>
      <FormattedMessage defaultMessage="Guest Details" id="details.breadcrumbTitle" />
    </li>
    <li>
      <FormattedMessage defaultMessage="Confirmation" id="confirmation.breadcrumbTitle" />
    </li>
  </ol>
);

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;
`;

class AddRoomPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numAdults: 1,
      numChildren: 0,
    };

    this.getFieldset = this.getFieldset.bind(this);
    this.incrementStateKey = this.incrementStateKey.bind(this);
    this.decrementStateKey = this.decrementStateKey.bind(this);
    this.createNumberHandler = this.createNumberHandler.bind(this);
  }

  getFieldset(isAdults = true) {
    const { maxOccupancy } = this.props;
    const { numAdults, numChildren } = this.state;

    const key = isAdults ? 'numAdults' : 'numChildren';
    const current = isAdults ? numAdults : numChildren;
    const other = isAdults ? numChildren : numAdults;

    return (
      <CounterFieldset>
        <button
          disabled={isAdults ? current <= 1 : current <= 0}
          onClick={() => this.decrementStateKey(key)}
          type="button"
        >
          <MinusSymbol width="2px" />
        </button>
        <button
          disabled={current >= (maxOccupancy - other)}
          onClick={() => this.incrementStateKey(key)}
          type="button"
        >
          <PlusSymbol width="2px" />
        </button>
        <input
          id={key}
          max={maxOccupancy - other}
          min={isAdults ? '1' : '0'}
          onChange={this.createNumberHandler(key)}
          type="number"
          value={current}
        />
      </CounterFieldset>
    );
  }

  incrementStateKey(key) {
    this.setState((prevState) => {
      const prevValue = parseToNumber(prevState[key]);
      return { [key]: prevValue + 1 };
    });
  }

  decrementStateKey(key) {
    this.setState((prevState) => {
      const prevValue = parseToNumber(prevState[key]);
      return { [key]: prevValue - 1 };
    });
  }

  createNumberHandler(key) {
    return ({ target }) => {
      const newValue = handleNumberInput(target);

      if (typeof (newValue) !== 'undefined') {
        this.setState({
          [key]: newValue,
        });
      }
    };
  }

  render() {
    const { addOnsAvailable, addRoom, intl: { formatMessage, locale }, location, maxOccupancy, stay, windowSize } = this.props;
    const { roomId } = location.state;
    const breadcrumbs = getBreadcrumbs(addOnsAvailable);
    const pageTitle = (
      <FormattedMessage
        defaultMessage="Add another room"
        id="booking.addARoomPageTitle"
      />
    );

    return (
      <StaticQuery
        query={graphql`
          {
            allDatoCmsFooter {
              edges {
                node {
                  ...footerBasicFields
                }
              }
            }
          }
        `}
        render={(data) => {
          const footerData = getSingletonForLang(data.allDatoCmsFooter, locale);
          const { numAdults, numChildren } = this.state;
          return (
            <BookingLayout
              breadcrumbs={breadcrumbs}
              footerData={footerData}
              location={location}
              metaTitle={formatMessage({ defaultMessage: 'Add another room', id: 'booking.addARoomPageTitle' })}
              roomId={roomId}
              title={pageTitle}
            >
              <Container>
                <Heading>
                  <FormattedMessage
                    description="Heading for new room details"
                    id="booking.addARoomDetails"
                    values={{ roomNumber: roomId }}
                  />
                </Heading>
                <ErrorMessage />
                <p>
                  <FormattedDate
                    day="numeric"
                    month="short"
                    value={moment(stay.arrivalDate)}
                    weekday="short"
                    year="numeric"
                  /> –&nbsp;
                  <FormattedDate
                    day="numeric"
                    month="short"
                    value={moment(stay.arrivalDate).add(stay.duration, 'days')}
                    weekday="short"
                    year="numeric"
                  />
                </p>
                <p>{stay.roomTypeName}</p>
                <p>{stay.ratePlanName}</p>
              </Container>
              <Container>
                <Heading>
                  <FormattedMessage
                    description="Heading for adding new room guests"
                    id="booking.addARoomGuests"
                    values={{ roomNumber: roomId }}
                  />
                </Heading>
                <Description>
                  <FormattedMessage
                    description="Description for adding new room guests"
                    id="booking.addARoomGuestsDescription"
                    values={{ roomNumber: roomId, maxOccupancy }}
                  />
                </Description>
                <CounterWidget>
                  <With inputId="numAdults">
                    <label htmlFor={inputId}>{/* eslint-disable-line jsx-a11y/label-has-for */}
                      <p>
                        <FormattedMessage
                          description="Label for the number of adults or children"
                          id="addons.numberOfAdults"
                        />
                      </p>
                      {this.getFieldset(true)}
                    </label>
                  </With>
                </CounterWidget>
                <CounterWidget>
                  <With inputId="numChildren">
                    <label htmlFor={inputId}>{/* eslint-disable-line jsx-a11y/label-has-for */}
                      <p>
                        <FormattedMessage
                          description="Label for the number of adults or children"
                          id="addons.numberOfChildren"
                        />
                      </p>
                      {this.getFieldset(false)}
                    </label>
                  </With>
                </CounterWidget>
                <Footer windowSize={windowSize}>
                  <AddButton onClick={() => addRoom({ numAdults, numChildren, roomId })} type="button">Add Room {roomId}</AddButton>
                </Footer>
              </Container>
            </BookingLayout>
          );
        }}
      />
    );
  }
}

AddRoomPage.propTypes = {
  addOnsAvailable: PropTypes.bool.isRequired,
  intl: intlShape,
  addRoom: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  maxOccupancy: PropTypes.number.isRequired,
  stay: PropTypes.object.isRequired,
  windowSize: windowSizeTypes.isRequired,
};

const mapStateToProps = (state) => ({
  addOnsAvailable: !!getAvailableServices(state).length,
  maxOccupancy: getMaxOccupancy(state, getStay(state).roomType),
  stay: getStay(state),
  windowSize: getWindowSize(state),
});

const mapDispatchToProps = (dispatch) => ({
  addRoom: (payload) => dispatch({ type: BOOKING_ADD_ROOM_REQUESTED, payload }),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddRoomPage));
