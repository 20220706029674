import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

import { BOOKING_DETAILS_PAGE } from '../constants/locations';
import { fontStyles } from '../constants/styles';
import { getSingletonForLang } from '../helpers/i18n';
import { SELECT_SERVICES } from '../state/actionTypes';
import { getAdditionalRooms, getStay } from '../state/reducers';

import AddOn from './AddOn';
import BookingLayout from './BookingLayout';
import ErrorMessage from './ErrorMessage';
import LocalisedLink from './LocalisedLink';


const SkipLink = styled.span`
  ${fontStyles.checkout}
`;

const getBreadcrumbs = () => (
  <ol>
    <li className="isActive">
      <FormattedMessage defaultMessage="Add Ons" id="addons.breadcrumbTitle" />
    </li>
    <li>
      <FormattedMessage defaultMessage="Guest Details" id="details.breadcrumbTitle" />
    </li>
    <li>
      <FormattedMessage defaultMessage="Confirmation" id="confirmation.breadcrumbTitle" />
    </li>
  </ol>
);

const getBasePrice = (addon) => {
  const priceObj = addon.price[0];
  if (!priceObj.base) return null;
  return parseFloat(priceObj.base.amountAfterTax);
};

const getPriceClasses = (addon) => {
  const amountsObj = addon.price[0].additionalGuestAmounts;
  if (!amountsObj || !amountsObj.additionalGuestAmount) return null;

  const priceClasses = amountsObj.additionalGuestAmount;

  return priceClasses
    .map((p) => ({
      minAge: parseInt(p.ageQualifyingCode, 10),
      price: p.amount.amountAfterTax,
    }))
    .sort((a, b) => b.minAge - a.minAge);
};

const addonCommentAsText = (addon, name) => {
  const comment = addon.serviceDetails.comments.comment.find((c) => c.name === name);
  if (comment) {
    return comment.text;
  }
};

const addonCommentByName = (addon, name, isHTML = false) => {
  const commentText = addonCommentAsText(addon, name);
  if (commentText && isHTML) {
    return (
      <div dangerouslySetInnerHTML={{ __html: commentText }} />
    );
  }
  if (commentText) {
    return <p>{commentText}</p>;
  }
};

const AddOnsPage = ({ additionalRooms, intl: { formatMessage, locale }, location, selectServices, servicesAdded, servicesAvailable, stay }) => {
  const { roomId } = location.state;
  const breadcrumbs = getBreadcrumbs();
  const pageTitle = (
    <Fragment>
      <Choose>
        <When condition={roomId}>
          <FormattedMessage id="addons.pageTitleMultiRoom" values={{ roomNumber: roomId }} />
        </When>
        <Otherwise>
          <FormattedMessage id="addons.pageTitle" />
        </Otherwise>
      </Choose>
      <If condition={!Object.keys(servicesAdded).length}>
        <LocalisedLink to={BOOKING_DETAILS_PAGE}><SkipLink><FormattedMessage id="addons.skipLink" />&nbsp;›</SkipLink></LocalisedLink>
      </If>
    </Fragment>
  );

  return (
    <StaticQuery
      query={graphql`
        {
          allDatoCmsFooter {
            edges {
              node {
                ...footerBasicFields
              }
            }
          }
        }
      `}
      render={(data) => {
        const footerData = getSingletonForLang(data.allDatoCmsFooter, locale);
        const metaTitle = roomId ? formatMessage({ id: 'addons.pageTitleMultiRoom' }, { roomNumber: roomId }) : formatMessage({ id: 'addons.pageTitle' });

        const room = roomId ? additionalRooms[roomId] : null;
        const { adults, children } = room || stay;
        const chosenServices = room ? room.services : servicesAdded;
        const chosenServiceCodes = Object.keys(chosenServices);

        return (
          <BookingLayout
            breadcrumbs={breadcrumbs}
            canRequestAddRoom
            continueButtonHandler={selectServices}
            footerData={footerData}
            location={location}
            metaTitle={metaTitle}
            title={pageTitle}
          >
            <ErrorMessage />
            <For each="addon" of={servicesAvailable}>
              <With
                initialState={chosenServiceCodes.includes(addon.serviceInventoryCode) ? {
                  numAdultsSelected: chosenServices[addon.serviceInventoryCode].guests.adults,
                  numChildrenSelected: chosenServices[addon.serviceInventoryCode].guests.children,
                  numSelected: chosenServices[addon.serviceInventoryCode].guests.adults,
                  total: chosenServices[addon.serviceInventoryCode].total,
                } : {}}
              >
                <AddOn
                  key={addon.serviceInventoryCode}
                  alreadyAdded={chosenServiceCodes.includes(addon.serviceInventoryCode)}
                  basePrice={getBasePrice(addon)}
                  code={addon.serviceInventoryCode}
                  description={addonCommentByName(addon, 'Description', true)}
                  end={addon.price[addon.price.length - 1].effectiveDate}
                  initialState={initialState}
                  name={addonCommentAsText(addon, 'Category')}
                  numAdults={adults || 1}
                  numChildren={children || 0}
                  numNights={stay.duration}
                  priceClasses={getPriceClasses(addon)}
                  roomId={roomId}
                  start={addon.price[0].effectiveDate}
                  timeSpan={addon.serviceDetails?.timeSpan}
                  title={addonCommentByName(addon, 'Title') || addonCommentByName(addon, 'Category')}
                />
              </With>
            </For>
          </BookingLayout>
        );
      }}
    />
  );
};

AddOnsPage.propTypes = {
  additionalRooms: PropTypes.object.isRequired,
  intl: intlShape,
  location: PropTypes.object,
  selectServices: PropTypes.func.isRequired,
  servicesAdded: PropTypes.object.isRequired,
  servicesAvailable: PropTypes.array.isRequired,
  stay: PropTypes.object,
};

const mapStateToProps = (state) => ({
  additionalRooms: getAdditionalRooms(state),
  servicesAdded: state.booking.services,
  servicesAvailable: state.availability.services,
  stay: getStay(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectServices: () => dispatch({ type: SELECT_SERVICES }),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddOnsPage));
