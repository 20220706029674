import React from 'react';
import PropTypes from 'prop-types';


const CreditCardIcon = ({ children, height = 24, id = null, isCurrent, width = 37, viewBox = '' }) => (
  <svg
    className={isCurrent ? 'is-current' : null}
    fill="none"
    height={height}
    href="http://www.w3.org/1999/xlink"
    id={id}
    viewBox={viewBox || `0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {children}
  </svg>
);

CreditCardIcon.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  id: PropTypes.string,
  isCurrent: PropTypes.bool,
  width: PropTypes.number,
  viewBox: PropTypes.string,
};

export default CreditCardIcon;
