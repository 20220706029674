export const parseToNumber = (value) => (typeof (value) === 'string' ? 0 : parseInt(value, 10));

export const handleNumberInput = (target) => {
  /*
   * First we need to convert the incoming value to a integer, for any non integer-like
   * strings we expect this to evaluate to NaN.
   *
   * We also convert the input's `max` and `min` attributes to integers in
   * order to be able to compare them.
   * */
  const targetValue = parseInt(target.value, 10);
  const targetMax = parseInt(target.max, 10) || Math.min();
  const targetMin = parseInt(target.min, 10) || Math.max();
  /*
   * If the targetValue is NaN and anything _other_ than an empty string
   * or if it exceeds its maximum or minimum allowed value then it's an invalid value and we should bail out.
   */
  if ((isNaN(targetValue) && target.value !== '') || targetValue > targetMax || targetValue < targetMin) return; // eslint-disable-line no-restricted-globals

  return target.value === '' ? '' : targetValue;
};
