import React from 'react';

import CreditCardIcon from './CreditCardIcon';


const CCV = () => (
  <CreditCardIcon height={30} id="ccv-icon" width={46}>
    <rect fill="#DFDFDF" height="29.5" rx="2.75" stroke="#DFDFDF" width="45.5" x=".25" y=".25" /><path d="M0 3h46v6H0z" fill="#000" /><path d="M2 13h26v8H2z" fill="#FAF6D2" /><path d="M25.5 11.5h18v12h-18z" fill="#fff" stroke="#DC0436" /><path d="M30.321 15.056l-1.585.964v.642l1.222-.726v3.477h-1.257v.531h3.1v-.53h-1.256v-4.358h-.224zM33.185 19.944h3.017v-.558h-2.06l1.578-1.97c.258-.328.391-.65.391-1.061 0-.384-.098-.692-.307-.929-.23-.258-.58-.426-1.062-.426-.74 0-1.375.566-1.452 1.334l.594.111c.076-.523.39-.886.858-.886.273 0 .461.09.587.237.119.14.182.321.182.559 0 .307-.077.53-.398.928l-1.928 2.389v.272zM37.383 18.603c.084.852.747 1.397 1.557 1.397.929 0 1.579-.656 1.579-1.592 0-.901-.552-1.502-1.3-1.502a.882.882 0 0 0-.195.021l1.243-1.683v-.188h-2.654v.559h1.76l-1.11 1.522.244.328a.967.967 0 0 1 .433-.09c.58 0 .978.362.978 1.033 0 .67-.412 1.033-.978 1.033-.565 0-.887-.335-.97-.915l-.587.077z" fill="#000" />
  </CreditCardIcon>
);

export default CCV;
