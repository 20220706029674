import React from 'react';
import { Router } from '@reach/router';

import BookingRoute from '../components/BookingRoute';

/* The booking page doesn't really have any content of its own. It is client-rendered
 * only (see `create-client-only-paths.js`) because it depends on the user having
 * chosen a stay.
 * Here we check whether the path is a known booking route and if so, use the
 * `BookingRoute` component to ensure that we have a stay before trying to
 * render the appropriate page.
 */
const BookingSystem = (props) => (
  <Router>
    <BookingRoute path="/:locale/book/:slug" {...props} />
  </Router>
);

export default BookingSystem;
