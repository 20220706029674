import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { StaticQuery, graphql } from 'gatsby';
import { Formik, Form, Field } from 'formik';

import { COLOR_BLACK, COLOR_GRAY, COLOR_RED } from '../constants/colors';
import { BOOKING_ADDONS_PAGE } from '../constants/locations';
import { fontStyles } from '../constants/styles';
import { isFlexibleRate, isPrepayRate } from '../helpers/booking';
import creditCardType, {
  getTypeInfo, CardType, formatWithSpaces, luhnCheck, MAX_LENGTH, MAX_SPACES,
} from '../helpers/creditcard';
import { getSingletonForLang } from '../helpers/i18n';
import { BOOKING_SUBMIT_DETAILS_FORM } from '../state/actionTypes';
import { getAvailableServices, getStay } from '../state/reducers';

import AddButton from './AddButton';
import AmericanExpress from './AmericanExpress';
import BookingLayout from './BookingLayout';
import CCV from './CCV';
import DinersClub from './DinersClub';
import ErrorMessage from './ErrorMessage';
import {
  CheckboxInput, FieldError, FormItem, FormLabel, FormRow, FormSection,
  FormSectionHeading, FormSubmit, Icons, SelectInput, SelectWrapper, TextInput,
} from './Form';
import JCB from './JCB';
import LocalisedLink from './LocalisedLink';
import Mastercard from './Mastercard';
import Policy from './Policy';
import Visa from './Visa';


const optionalFields = ['companyName', 'isBusinessStay', 'addressLine2', 'specialReqs', 'arrivalTime'];
const isRequired = ((fieldName) => !optionalFields.includes(fieldName));

const ccvPattern = '^\\d{3,4}$';
const creditCardNumberPattern = '^[\\d\\s]*$';
const creditCardExpiryMonthPattern = '^((0[1-9])|(1[0-2]))$';
const creditCardExpiryYearPattern = '^\\d{2}$';

const Legal = styled.p`
  ${fontStyles.checkout}
  color: ${COLOR_GRAY};
`;

const Separator = styled.span`
  margin: 0 .5em;
  ${fontStyles.body}
`;

const getBreadcrumbs = (addOnsAvailable) => (
  <ol>
    <If condition={addOnsAvailable}>
      <li>
        <LocalisedLink hasUnderline={false} to={BOOKING_ADDONS_PAGE}><FormattedMessage defaultMessage="Add Ons" id="addons.breadcrumbTitle" /></LocalisedLink>
      </li>
    </If>
    <li className="isActive">
      <FormattedMessage defaultMessage="Guest Details" id="details.breadcrumbTitle" />
    </li>
    <li>
      <FormattedMessage defaultMessage="Confirmation" id="confirmation.breadcrumbTitle" />
    </li>
  </ol>
);

const getArrivalTimes = (formatTime, arrivalDate) => {
  let current = moment(arrivalDate);

  const arrivalTimes = [];
  for (let i = 0; i < 24; i += 1) {
    arrivalTimes.push({
      value: current.format('YYYY-MM-DDTHH:mm'),
      label: formatTime(current),
    });
    current = current.add(1, 'hours');
  }
  return arrivalTimes;
};

const getExpiryYears = (numYears = 20) => {
  const d = moment();
  let year = d.year();
  if (d.month() === 11) {
    year += 1;
  }
  const years = [];
  for (let i = year; i < year + numYears; i += 1) {
    years.push(i);
  }
  return years.map((y) => y.toString(10).substr(2));
};

const validateInputs = (values) => Object.keys(values)
  .reduce((errors, fieldName) => {
    if (isRequired(fieldName) && !values[fieldName]) {
      errors[fieldName] = 'details.errorRequired';
    } else if (fieldName === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors[fieldName] = 'details.errorInvalidEmailAddress';
    } else if (fieldName === 'creditCardExpiryMonth' && !RegExp(creditCardExpiryMonthPattern).test(values.creditCardExpiryMonth)) {
      errors[fieldName] = 'details.errorInvalidExpiryDate';
    } else if (fieldName === 'creditCardExpiryYear' && !RegExp(creditCardExpiryYearPattern).test(values.creditCardExpiryYear)) {
      errors[fieldName] = 'details.errorInvalidExpiryDate';
    } else if (fieldName === 'creditCardCode' && !RegExp(ccvPattern).test(values.creditCardCode)) {
      errors[fieldName] = 'details.errorInvalidSecurityCode';
    }
    return errors;
  }, {});

class DetailsPage extends Component {
  constructor(props) {
    super(props);

    const { details: { addressLine2, companyName, specialReqs } } = this.props;

    this.state = {
      ccNumberMaxLength: MAX_LENGTH + MAX_SPACES,
      ccNumberPattern: creditCardNumberPattern,
      ccType: undefined,
      hasAddressLine2: !!addressLine2,
      hasCompanyName: !!companyName,
      hasSpecialReqs: !!specialReqs,
    };

    this.validateCCNumber = this.validateCCNumber.bind(this);
    this.handleCCNumberChange = this.handleCCNumberChange.bind(this);
  }

  validateCCNumber(value) {
    let error;
    const strippedValue = value.replace(/\D/g, '');
    if (!luhnCheck(strippedValue)) {
      error = 'details.errorInvalidCreditCardNumber';
    }
    return error;
  }

  handleCCNumberChange(e, handleChange) {
    let maxLength = MAX_LENGTH + MAX_SPACES;
    let pattern = creditCardNumberPattern;
    let finalSpace = false;

    const { value } = e.target;
    if (value[value.length - 1] === ' ') {
      finalSpace = true;
    }
    const cardType = creditCardType(value);

    if (cardType) {
      const cardTypeInfo = getTypeInfo(cardType);
      pattern = cardTypeInfo.fullPattern;
      maxLength = cardTypeInfo.length + cardTypeInfo.grouping.length - 1;

      const strippedValue = value.replace(/\s/g, '');
      let formattedValue = formatWithSpaces(strippedValue, cardTypeInfo.grouping);
      if (finalSpace) formattedValue += ' ';
      e.target.value = formattedValue;
    }

    handleChange(e);

    this.setState({
      ccNumberMaxLength: maxLength,
      ccNumberPattern: pattern,
      ccType: cardType,
    });
  }

  render() {
    const {
      addOnsAvailable, details, intl: { formatMessage, formatTime, locale },
      location, stay, submitForm,
    } = this.props;
    const { ccNumberMaxLength, ccNumberPattern, ccType, hasAddressLine2, hasCompanyName, hasSpecialReqs } = this.state;
    const breadcrumbs = getBreadcrumbs(addOnsAvailable);

    return (
      <StaticQuery
        query={graphql`
          {
            allDatoCmsFooter {
              edges {
                node {
                  ...footerBasicFields
                }
              }
            }
          }
        `}
        render={(data) => {
          const footerData = getSingletonForLang(data.allDatoCmsFooter, locale);
          return (
            <BookingLayout
              breadcrumbs={breadcrumbs}
              canRequestAddRoom
              footerData={footerData}
              location={location}
              metaTitle={formatMessage({ id: 'details.pageTitle' })}
              title={<FormattedMessage id="details.pageTitle" />}
            >
              <ErrorMessage />
              <Formik
                initialValues={{
                  fullName: details.fullName || '',
                  phone: details.phone || '',
                  email: details.email || '',
                  companyName: details.companyName || '',
                  isBusinessStay: details.isBusinessStay || false,
                  addressLine1: details.addressLine1 || '',
                  addressLine2: details.addressLine2 || '',
                  city: details.city || '',
                  postalCode: details.postalCode || '',
                  country: details.country || '',
                  specialReqs: details.specialReqs || '',
                  arrivalTime: details.arrivalTime || '',
                  creditCardNumber: details.creditCardNumber || '',
                  creditCardExpiryMonth: details.creditCardExpiryMonth || '',
                  creditCardExpiryYear: details.creditCardExpiryYear || '',
                  creditCardCode: '',
                  creditCardName: details.creditCardName || '',
                  privacyPolicy: details.privacyPolicy || false,
                  termsConditions: details.termsConditions || false,
                }}
                onSubmit={(values) => { submitForm(values); }}
                render={({
                  errors,
                  handleChange,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <Form>
                    <FormSection>
                      <FormRow>
                        <TextInput
                          autoComplete="name"
                          error={touched.fullName && errors.fullName}
                          id="fullName"
                          label="details.personalDetailsFullName"
                          required={isRequired('fullName')}
                        />
                      </FormRow>
                      <FormRow>
                        <TextInput
                          autoComplete="tel"
                          error={touched.phone && errors.phone}
                          id="phone"
                          label="details.personalDetailsPhoneNumber"
                          required={isRequired('phone')}
                          type="tel"
                        />
                      </FormRow>
                      <FormRow>
                        <TextInput
                          autoComplete="email"
                          error={touched.email && errors.email}
                          hint="details.personalDetailsEmailAddressHint"
                          id="email"
                          label="details.personalDetailsEmailAddress"
                          required={isRequired('email')}
                          type="email"
                        />
                      </FormRow>
                    </FormSection>
                    <FormSection>
                      <FormSectionHeading><FormattedMessage id="details.addressTitle" /></FormSectionHeading>
                      <FormRow>
                        <TextInput
                          autoComplete="address-line1"
                          error={touched.addressLine1 && errors.addressLine1}
                          id="addressLine1"
                          label="details.addressLine1"
                          required={isRequired('addressLine1')}
                        />
                      </FormRow>
                      <Choose>
                        <When condition={hasAddressLine2}>
                          <FormRow>
                            <TextInput
                              autoComplete="address-line2"
                              error={touched.addressLine2 && errors.addressLine2}
                              id="addressLine2"
                              required={isRequired('addressLine2')}
                            />
                          </FormRow>
                        </When>
                        <Otherwise>
                          <p>
                            <AddButton clickHandler={() => { this.setState({ hasAddressLine2: true }); }}><FormattedMessage id="details.addressAddSecondLine" /></AddButton>
                          </p>
                        </Otherwise>
                      </Choose>
                      <Choose>
                        <When condition={hasCompanyName}>
                          <FormRow>
                            <TextInput
                              autoComplete="organization"
                              error={touched.companyName && errors.companyName}
                              id="companyName"
                              label="details.addressCompanyName"
                              required={isRequired('companyName')}
                            />
                          </FormRow>
                          <If condition={values.isBusinessStay || values.companyName}>
                            <FormRow>
                              <CheckboxInput
                                error={touched.isBusinessStay && errors.isBusinessStay}
                                id="isBusinessStay"
                                label="details.addressBusinessStay"
                                required={isRequired('isBusinessStay')}
                              />
                            </FormRow>
                          </If>
                        </When>
                        <Otherwise>
                          <p>
                            <AddButton clickHandler={() => { this.setState({ hasCompanyName: true }); }}><FormattedMessage id="details.addressAddCompanyName" /></AddButton>
                          </p>
                        </Otherwise>
                      </Choose>
                      <FormRow>
                        <TextInput
                          autoComplete="postal-code"
                          error={touched.postalCode && errors.postalCode}
                          id="postalCode"
                          label="details.addressPostalCode"
                          required={isRequired('postalCode')}
                        />
                        <TextInput
                          autoComplete="address-level2"
                          error={touched.city && errors.city}
                          id="city"
                          label="details.addressCity"
                          required={isRequired('addressCity')}
                        />
                      </FormRow>
                      <FormRow>
                        <TextInput
                          autoComplete="country-name"
                          error={touched.country && errors.country}
                          id="country"
                          label="details.addressCountry"
                          required={isRequired('country')}
                        />
                      </FormRow>
                    </FormSection>
                    <FormSection>
                      <FormSectionHeading><FormattedMessage id="details.additionalDetailsTitle" /></FormSectionHeading>
                      <FormRow>
                        <SelectInput
                          error={touched.arrivalTime && errors.arrivalTime}
                          id="arrivalTime"
                          label="details.additionalDetailsArrivalTime"
                          options={getArrivalTimes(formatTime, stay.arrivalDate)}
                          required={isRequired('arrivalTime')}
                          width={50}
                        />
                      </FormRow>
                      <Choose>
                        <When condition={hasSpecialReqs}>
                          <FormRow>
                            <TextInput
                              component="textarea"
                              error={touched.specialReqs && errors.specialReqs}
                              id="specialReqs"
                              label="details.additionalDetailsSpecialRequests"
                              required={isRequired('specialReqs')}
                              rows={5}
                            />
                          </FormRow>
                        </When>
                        <Otherwise>
                          <p>
                            <AddButton clickHandler={() => { this.setState({ hasSpecialReqs: true }); }}><FormattedMessage id="details.additionalDetailsAddSpecialRequests" /></AddButton>
                          </p>
                        </Otherwise>
                      </Choose>
                    </FormSection>
                    <FormSection hasBG>
                      <FormSectionHeading>
                        <Choose>
                          <When condition={stay.policies.guarantee && isPrepayRate(stay.policies.guarantee.code)}>
                            <FormattedMessage id="details.creditCardTitlePayment" />
                          </When>
                          <When condition={stay.policies.guarantee && isFlexibleRate(stay.policies.guarantee.code)}>
                            <FormattedMessage id="details.creditCardTitleGuarantee" />
                          </When>
                        </Choose>
                      </FormSectionHeading>
                      <Legal>
                        <Choose>
                          <When condition={stay.policies.guarantee && isPrepayRate(stay.policies.guarantee.code)}>
                            <FormattedMessage id="details.creditCardDescriptionNonRefundable" />
                          </When>
                          <When condition={stay.policies.guarantee && isFlexibleRate(stay.policies.guarantee.code)}>
                            <FormattedMessage id="details.creditCardDescriptionGuarantee" />
                          </When>
                        </Choose>
                      </Legal>
                      <FormRow>
                        <TextInput
                          autoComplete="cc-number"
                          error={touched.creditCardNumber && errors.creditCardNumber}
                          icons={(
                            <Icons>
                              <Visa isCurrent={ccType === CardType.VISA} />
                              <Mastercard isCurrent={ccType === CardType.MASTERCARD} />
                              <AmericanExpress isCurrent={ccType === CardType.AMEX} viewBox="0 0 160 100" />
                              <DinersClub isCurrent={ccType === CardType.DINERS} />
                              <JCB isCurrent={ccType === CardType.JCB_15 || ccType === CardType.JCB} viewBox="0 0 750 471" />
                            </Icons>
                          )}
                          id="creditCardNumber"
                          label="details.creditCardNumber"
                          maxLength={ccNumberMaxLength}
                          onChange={(e) => this.handleCCNumberChange(e, handleChange)}
                          pattern={ccNumberPattern}
                          required={isRequired('creditCardNumber')}
                          style={{ minWidth: '14em' }}
                          validate={this.validateCCNumber}
                        />
                      </FormRow>
                      <FormRow>
                        <FormItem>
                          <FormLabel
                            hasError={(touched.creditCardExpiryMonth && errors.creditCardExpiryMonth) || (touched.creditCardExpiryYear && errors.creditCardExpiryYear)}
                            htmlFor="creditCardExpiryMonth"
                          >
                            <FormattedMessage id="details.creditCardExpiration" />
                            <If condition={!isRequired('creditCardExpiryMonth') && !isRequired('creditCardExpiryYear')}>&nbsp;(<FormattedMessage id="details.optional" />)</If>
                          </FormLabel>
                          <SelectWrapper style={{ display: 'inline-block', width: '4em' }}>
                            <Field
                              component="select"
                              id="creditCardExpiryMonth"
                              name="creditCardExpiryMonth"
                              pattern={creditCardExpiryMonthPattern}
                              required={isRequired('creditCardExpiryMonth')}
                              style={{ borderColor: (touched.creditCardExpiryMonth && errors.creditCardExpiryMonth) ? COLOR_RED : COLOR_BLACK }}
                            >
                              <option key="default">-</option>
                              <For each="opt" of={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']}>
                                <option key={opt} value={opt}>{opt}</option>
                              </For>
                            </Field>
                          </SelectWrapper>
                          <Separator> / </Separator>
                          <SelectWrapper style={{ display: 'inline-block', width: '4em' }}>
                            <Field
                              component="select"
                              id="creditCardExpiryYear"
                              name="creditCardExpiryYear"
                              pattern={creditCardExpiryYearPattern}
                              required={isRequired('creditCardExpiryYear')}
                              style={{ borderColor: (touched.creditCardExpiryYear && errors.creditCardExpiryYear) ? COLOR_RED : COLOR_BLACK }}
                            >
                              <option key="default">-</option>
                              <For each="opt" of={getExpiryYears()}>
                                <option key={opt} value={opt}>{opt}</option>
                              </For>
                            </Field>
                          </SelectWrapper>
                          <With error={(touched.creditCardExpiryMonth && errors.creditCardExpiryMonth) || (touched.creditCardExpiryYear && errors.creditCardExpiryYear)}>
                            <If condition={!!error}>
                              <FieldError><FormattedMessage id={error} /></FieldError>
                            </If>
                          </With>
                        </FormItem>
                      </FormRow>
                      <FormRow>
                        <TextInput
                          autoComplete="cc-csc"
                          error={touched.creditCardCode && errors.creditCardCode}
                          icons={<Icons style={{ flex: '1 0 2.875rem', marginLeft: '1rem', marginTop: '0' }}><CCV /></Icons>}
                          id="creditCardCode"
                          label="details.creditCardSecurityCode"
                          maxLength={ccType === CardType.AMEX ? '4' : '3'}
                          pattern={ccvPattern}
                          required={isRequired('creditCardCode')}
                          style={{ width: '7.5em' }}
                          type="text"
                        />
                      </FormRow>
                      <FormRow>
                        <TextInput
                          autoComplete="cc-name"
                          error={touched.creditCardName && errors.creditCardName}
                          id="creditCardName"
                          label="details.creditCardName"
                          required={isRequired('creditCardName')}
                        />
                      </FormRow>
                    </FormSection>
                    <FormSection>
                      <FormSectionHeading><FormattedMessage id="details.bookingConditionsTitle" /></FormSectionHeading>
                      <Policy name={<FormattedMessage id="details.bookingConditionsCheckInTitle">{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
                        <div>
                          <FormattedMessage id="details.bookingConditionsCheckInText" />
                        </div>
                      </Policy>
                      <Policy name={<FormattedMessage id="details.bookingConditionsCheckOutTitle">{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
                        <div>
                          <FormattedMessage id="details.bookingConditionsCheckOutText" />
                        </div>
                      </Policy>
                      <If condition={stay.policies.cancel}>
                        <Policy name={<FormattedMessage id="booking.cancellation">{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
                          <For each="cancellationPolicy" index="idx" of={stay.policies.cancel}>
                            <div key={`${stay.ratePlan}-cancellation-${idx}`} dangerouslySetInnerHTML={{ __html: cancellationPolicy.desc }} />
                          </For>
                        </Policy>
                      </If>
                      <If condition={stay.policies.guarantee}>
                        <Policy name={<FormattedMessage id={isPrepayRate(stay.policies.guarantee.code) ? 'booking.payment' : 'booking.guarantee'}>{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
                          <div dangerouslySetInnerHTML={{ __html: stay.policies.guarantee.desc }} />
                        </Policy>
                      </If>
                    </FormSection>
                    <FormSection>
                      <FormSectionHeading><FormattedMessage id="details.acknowledgementTitle" /></FormSectionHeading>
                      <FormRow>
                        <CheckboxInput
                          id="privacyPolicy"
                          isMarkdown
                          label={formatMessage({ id: 'details.acknowledgementPrivacyPolicy' })}
                          required={isRequired('privacyPolicy')}
                        />
                      </FormRow>
                      <FormRow>
                        <CheckboxInput
                          id="termsConditions"
                          isMarkdown
                          label={formatMessage({ id: 'details.acknowledgementTermsConditions' })}
                          required={isRequired('termsConditions')}
                        />
                      </FormRow>
                    </FormSection>
                    <FormRow>
                      <FormSubmit disabled={isSubmitting} type="submit">
                        <FormattedMessage id={isSubmitting ? 'details.submitButtonInProgress' : 'details.submitButton'} />
                      </FormSubmit>
                    </FormRow>
                  </Form>
                )}
                validate={validateInputs}
                validateOnBlur
                validateOnChange={false}
              />
            </BookingLayout>
          );
        }}
      />
    );
  }
}

DetailsPage.propTypes = {
  addOnsAvailable: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  location: PropTypes.object,
  stay: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  addOnsAvailable: !!getAvailableServices(state).length,
  details: state.booking.details,
  stay: getStay(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: (payload) => dispatch({ type: BOOKING_SUBMIT_DETAILS_FORM, payload }),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DetailsPage));
