import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  injectIntl, intlShape, FormattedDate, FormattedMessage, FormattedNumber,
} from 'react-intl';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import moment from 'moment';

import { VALUE_PARKING } from '../constants/api';
import { COLOR_BLACK } from '../constants/colors';
import { breakpoints } from '../constants/mediaqueries';
import { fontStyles } from '../constants/styles';
import { getAddOn } from '../helpers/addons';
import { isFlexibleRate, isPrepayRate } from '../helpers/booking';
import { getSingletonForLang } from '../helpers/i18n';
import { customMarkdown } from '../helpers/markdown';
import { CANCEL_BOOKING_REQUESTED } from '../state/actionTypes';
import { getRetrievedReservation } from '../state/reducers';

import { Addon } from './BookingBreakdown';
import BookingLayout from './BookingLayout';
import FormattedPrice from './FormattedPrice';
import { Container, Heading, MarkdownContent } from './ConfirmationPage';
import Policy from './Policy';


const ReservationContainer = styled(Container)`
  @media ${breakpoints.l} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Summary = styled(Container)`
  margin-top: .5em;
  ${fontStyles.checkout}
`;

const SubHeading = styled.h3`
  margin-top: 1.5rem;
  ${fontStyles.uppercase};
`;

const LineItem = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${fontStyles.checkout}
`;

const Total = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: .5em;
  padding-top: .5em;
  border-top: 1px solid ${COLOR_BLACK};

  ${fontStyles.bodySmall}
`;

const ReservationPage = ({ intl: { formatMessage, locale }, location, requestCancelBooking, reservation }) => {
  const { cancellationId, confirmationId, creditCardSuffix, guestDetails, cancelPolicy, guaranteePolicy, services, stay } = reservation;
  const pageTitle = (
    <FormattedMessage
      id="confirmation.pageTitle"
      values={{ confirmationId }}
    />
  );

  return (
    <StaticQuery
      query={graphql`
        {
          allDatoCmsFooter {
            edges {
              node {
                ...footerBasicFields
              }
            }
          }
        }
      `}
      render={(data) => {
        const footerData = getSingletonForLang(data.allDatoCmsFooter, locale);

        const addons = services.map((s) => getAddOn(s, stay.duration));
        const total = addons.reduce((runningTotal, addon) => (runningTotal + addon.total), parseFloat(stay.total));

        return (
          <BookingLayout
            cancelButtonHandler={requestCancelBooking}
            footerData={footerData}
            isPostBooking
            location={location}
            metaTitle={formatMessage({ id: 'confirmation.pageTitle' }, { confirmationId })}
            showModifyBookingMessage
            title={pageTitle}
          >
            <Summary>
              <p>
                <FormattedDate
                  day="numeric"
                  month="short"
                  value={moment(stay.arrivalDate)}
                  weekday="short"
                  year="numeric"
                /> –&nbsp;
                <FormattedDate
                  day="numeric"
                  month="short"
                  value={moment(stay.arrivalDate).add(stay.duration, 'days')}
                  weekday="short"
                  year="numeric"
                />
              </p>
              <p>
                <FormattedMessage
                  description="Line item declaring number of adults"
                  id="booking.adultsWithNumber"
                  values={{
                    formattedNumAdults: (<FormattedNumber value={stay.adults} />),
                    numAdults: stay.adults,
                  }}
                />
                <If condition={stay.children}>
                  &nbsp;<FormattedMessage id="booking.and" />&nbsp;
                  <FormattedMessage
                    description="Line item declaring number of children"
                    id="booking.childrenWithNumber"
                    values={{
                      formattedNumChildren: (<FormattedNumber value={stay.children} />),
                      numChildren: stay.children,
                    }}
                  />
                </If>
              </p>
              <p><If condition={stay.numRooms > 1}>{stay.numRooms} × </If>{stay.roomTypeName}</p>
              <p>{stay.ratePlanName}</p>
              <LineItem>
                <FormattedMessage
                  description="Line item declaring number of nights"
                  id="booking.nightsWithNumber"
                  values={{
                    formattedNumNights: (<FormattedNumber value={stay.duration} />),
                    numNights: stay.duration,
                  }}
                />
                <FormattedPrice value={parseFloat(stay.total)} />
              </LineItem>
              <If condition={services.length}>
                <SubHeading>
                  <FormattedMessage
                    defaultMessage="Addons"
                    description="Addons subheading"
                    id="booking.addonsSubheading"
                  />
                </SubHeading>
              </If>
              <For each="addon" of={addons}>
                <Addon key={addon.code}>
                  <LineItem>
                    <span>{addon.title}</span>
                  </LineItem>
                  <Choose>
                    <When condition={addon.basePrice}>
                      <LineItem>
                        <Choose>
                          <When condition={addon.code === VALUE_PARKING}>
                            <FormattedMessage
                              description="Line item declaring number of vehicles"
                              id="booking.vehiclesWithNumber"
                              values={{
                                numVehicles: addon.guests[0].count,
                              }}
                            />
                          </When>
                          <Otherwise>
                            <FormattedMessage
                              description="Line item declaring number of guests"
                              id="booking.guestsWithNumber"
                              values={{
                                formattedNumGuests: (<FormattedNumber value={addon.guests[0].count} />),
                                numGuests: addon.guests[0].count,
                              }}
                            />
                          </Otherwise>
                        </Choose>
                        <FormattedPrice value={addon.total} />
                      </LineItem>
                    </When>
                    <Otherwise>
                      <LineItem>
                        <With numGuests={addon.guests.reduce((acc, g) => acc + g.count, 0)}>
                          <FormattedMessage
                            description="Line item declaring number of guests"
                            id="booking.guestsWithNumber"
                            values={{
                              formattedNumGuests: (<FormattedNumber value={numGuests} />),
                              numGuests,
                            }}
                          />
                        </With>
                        <FormattedPrice value={addon.total} />
                      </LineItem>
                    </Otherwise>
                  </Choose>
                </Addon>
              </For>
              <Total>
                <FormattedMessage
                  description="Label for the total costs"
                  id="booking.total"
                /> <FormattedPrice value={total} />
              </Total>
            </Summary>
            <ReservationContainer>
              <Heading><FormattedMessage id="details.pageTitle" /></Heading>
              <p>
                {guestDetails.name}<br />
                {guestDetails.telephone}<br />
                {guestDetails.email}<br />
                <For each="addressLine" of={guestDetails.addressLines}>
                  {addressLine}<br />
                </For>
                {guestDetails.addressCity} {guestDetails.postalCode}
              </p>
            </ReservationContainer>
            <ReservationContainer>
              <Heading>
                <Choose>
                  <When condition={isPrepayRate(guaranteePolicy.code)}>
                    <FormattedMessage id="details.creditCardTitlePayment" />
                  </When>
                  <When condition={isFlexibleRate(guaranteePolicy.code)}>
                    <FormattedMessage id="details.creditCardTitleGuarantee" />
                  </When>
                </Choose>
              </Heading>
              <p>
                <FormattedMessage
                  id="confirmation.paymentDescription"
                  values={{ creditCardSuffix }}
                />
              </p>
            </ReservationContainer>
            <ReservationContainer>
              <Heading><FormattedMessage id="details.bookingConditionsTitle" /></Heading>
              <Policy name={<FormattedMessage id="details.bookingConditionsCheckInTitle">{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
                <div>
                  <FormattedMessage id="details.bookingConditionsCheckInText" />
                </div>
              </Policy>
              <Policy name={<FormattedMessage id="details.bookingConditionsCheckOutTitle">{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
                <div>
                  <FormattedMessage id="details.bookingConditionsCheckOutText" />
                </div>
              </Policy>
              <If condition={cancelPolicy}>
                <Policy name={<FormattedMessage id="booking.cancellation">{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
                  <div dangerouslySetInnerHTML={{ __html: cancelPolicy.text }} />
                </Policy>
              </If>
              <If condition={guaranteePolicy}>
                <Policy
                  name={(
                    <FormattedMessage id={isPrepayRate(guaranteePolicy.code) ? 'booking.payment' : 'booking.guarantee'}>
                      {(txt) => (<span>{txt.toLowerCase()}</span>)}
                    </FormattedMessage>
                  )}
                >
                  <div dangerouslySetInnerHTML={{ __html: guaranteePolicy.text }} />
                </Policy>
              </If>
            </ReservationContainer>
            <If condition={!cancellationId}>
              <ReservationContainer hasBG>
                <MarkdownContent>{customMarkdown(formatMessage({ id: 'confirmation.welcome' }))}</MarkdownContent>
              </ReservationContainer>
            </If>
          </BookingLayout>
        );
      }}
    />
  );
};

ReservationPage.propTypes = {
  intl: intlShape,
  location: PropTypes.object.isRequired,
  requestCancelBooking: PropTypes.func.isRequired,
  reservation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  reservation: getRetrievedReservation(state),
});

const mapDispatchToProps = (dispatch) => ({
  requestCancelBooking: () => dispatch({ type: CANCEL_BOOKING_REQUESTED }),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReservationPage));
