import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  injectIntl, intlShape, FormattedDate, FormattedMessage,
} from 'react-intl';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

import { COLOR_BLACK, COLOR_GRAY_BG } from '../constants/colors';
import { BOOKING_ADDONS_PAGE, BOOKING_DETAILS_PAGE } from '../constants/locations';
import { SPACING_SIDE_MOBILE_HALF } from '../constants/spacings';
import { bulletStyles, fontStyles } from '../constants/styles';
import { isFlexibleRate, isPrepayRate } from '../helpers/booking';
import { getSingletonForLang } from '../helpers/i18n';
import { customMarkdown } from '../helpers/markdown';
import {
  getAvailableServices, getBookingDetails, getReservation, getServicesList,
} from '../state/reducers';

import BookingLayout from './BookingLayout';
import { Error } from './ErrorMessage';
import LocalisedLink from './LocalisedLink';
import Policy from './Policy';


const AddOnError = styled(Error)`
  display: block;

  ul li {
    ${bulletStyles}
  }
`;

export const Heading = styled.h2`
  margin-bottom: 1em;
  ${fontStyles.body}
`;

export const Container = styled.section`
  margin-top: 2em;
  margin-left: ${(props) => (props.hasBG ? `-${SPACING_SIDE_MOBILE_HALF}` : '0')};
  margin-right: ${(props) => (props.hasBG ? `-${SPACING_SIDE_MOBILE_HALF}` : '0')};
  padding: ${(props) => (props.hasBG ? SPACING_SIDE_MOBILE_HALF : '0')};

  ${(props) => props.hasBG && `background-color: ${COLOR_GRAY_BG}`}
  ${fontStyles.bodySmall}

  h1 {
    margin-bottom: 1em;
    ${fontStyles.body}
  }
`;

export const MarkdownContent = styled.div`
  /* Spacing */
  * + * {
    margin-top: 1em; /* Generic spacing rule */
  }

  * + p {
    margin-top: 1.5em;
  }

  * + ul,
  * + ol,
  * + li {
    margin-top: 0;
  }

  * + h1, * + h2, * + h3, * + h4, * + h5, * + h6 {
    margin-top: 2.5em;
  }
`;


const getBreadcrumbs = (addOnsAvailable) => (
  <ol>
    <If condition={addOnsAvailable}>
      <li>
        <LocalisedLink hasUnderline={false} to={BOOKING_ADDONS_PAGE}><FormattedMessage defaultMessage="Add Ons" id="addons.breadcrumbTitle" /></LocalisedLink>
      </li>
    </If>
    <li>
      <LocalisedLink hasUnderline={false} to={BOOKING_DETAILS_PAGE}><FormattedMessage defaultMessage="Guest Details" id="details.breadcrumbTitle" /></LocalisedLink>
    </li>
    <li className="isActive">
      <FormattedMessage defaultMessage="Confirmation" id="confirmation.breadcrumbTitle" />
    </li>
  </ol>
);


const ConfirmationPage = ({ addOnsAvailable, details, intl: { formatMessage, locale }, location, reservation, services }) => {
  const breadcrumbs = getBreadcrumbs(addOnsAvailable);

  const { creditCardSuffix } = details;
  const { confirmationId, guestDetails, cancelPolicy, guaranteePolicy } = reservation;

  /*
   * This logic will probably need to change after we've seen what multi-room
   * bookings with services look like.
   */
  const missingServices = services
    .filter((s) => !reservation.services.some((resService) => resService.attributes.serviceInventoryCode === s.code));

  const pageTitle = (
    <FormattedMessage
      id="confirmation.pageTitle"
      values={{ confirmationId }}
    />
  );

  return (
    <StaticQuery
      query={graphql`
        {
          allDatoCmsFooter {
            edges {
              node {
                ...footerBasicFields
              }
            }
          }
        }
      `}
      render={(data) => {
        const footerData = getSingletonForLang(data.allDatoCmsFooter, locale);
        return (
          <BookingLayout
            breadcrumbs={breadcrumbs}
            footerData={footerData}
            isConfirmationPage
            location={location}
            metaTitle={formatMessage({ id: 'confirmation.pageTitle' }, { confirmationId })}
            showModifyBookingMessage
            title={pageTitle}
          >
            <If condition={missingServices.length}>
              <Container>
                <AddOnError bgColor={COLOR_GRAY_BG} color={COLOR_BLACK}>
                  <p>
                    <FormattedMessage
                      id="confirmation.servicesNotBooked"
                      values={{ missingServicesCount: missingServices.length }}
                    />
                  </p>
                  <ul>
                    <For each="addon" of={missingServices}>
                      <li key={`${addon.code}-${addon.start}`}>{addon.name}&nbsp;
                        <FormattedDate value={addon.start} />
                      </li>
                    </For>
                  </ul>
                </AddOnError>
              </Container>
            </If>
            <Container>
              <Heading><FormattedMessage id="details.pageTitle" /></Heading>
              <p>
                {guestDetails.name}<br />
                {guestDetails.telephone}<br />
                {guestDetails.email}<br />
                <For each="addressLine" of={guestDetails.addressLines}>
                  {addressLine}<br />
                </For>
                {guestDetails.addressCity} {guestDetails.postalCode}
              </p>
            </Container>
            <Container>
              <Heading>
                <Choose>
                  <When condition={isPrepayRate(guaranteePolicy.code)}>
                    <FormattedMessage id="details.creditCardTitlePayment" />
                  </When>
                  <When condition={isFlexibleRate(guaranteePolicy.code)}>
                    <FormattedMessage id="details.creditCardTitleGuarantee" />
                  </When>
                </Choose>
              </Heading>
              <p>
                <FormattedMessage
                  id="confirmation.paymentDescription"
                  values={{ creditCardSuffix }}
                />
              </p>
            </Container>
            <Container>
              <Heading><FormattedMessage id="details.bookingConditionsTitle" /></Heading>
              <Policy name={<FormattedMessage id="details.bookingConditionsCheckInTitle">{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
                <div>
                  <FormattedMessage id="details.bookingConditionsCheckInText" />
                </div>
              </Policy>
              <Policy name={<FormattedMessage id="details.bookingConditionsCheckOutTitle">{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
                <div>
                  <FormattedMessage id="details.bookingConditionsCheckOutText" />
                </div>
              </Policy>
              <If condition={cancelPolicy}>
                <Policy name={<FormattedMessage id="booking.cancellation">{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
                  <div dangerouslySetInnerHTML={{ __html: cancelPolicy.text }} />
                </Policy>
              </If>
              <If condition={guaranteePolicy}>
                <Policy name={<FormattedMessage id={isPrepayRate(guaranteePolicy.code) ? 'booking.payment' : 'booking.guarantee'}>{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
                  <div dangerouslySetInnerHTML={{ __html: guaranteePolicy.text }} />
                </Policy>
              </If>
            </Container>
            <Container hasBG>
              <MarkdownContent>{customMarkdown(formatMessage({ id: 'confirmation.welcome' }))}</MarkdownContent>
            </Container>
          </BookingLayout>
        );
      }}
    />
  );
};

ConfirmationPage.propTypes = {
  addOnsAvailable: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
  intl: intlShape,
  location: PropTypes.object.isRequired,
  reservation: PropTypes.object.isRequired,
  services: PropTypes.array,
};

const mapStateToProps = (state) => ({
  addOnsAvailable: !!getAvailableServices(state).length,
  details: getBookingDetails(state),
  reservation: getReservation(state),
  services: getServicesList(state),
});

export default injectIntl(connect(mapStateToProps)(ConfirmationPage));
